import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IFilmDetailsDto } from "../../dtos/film-details-dto";
import { ScreeningService } from "../../services/screening.service";

@Component({
  selector: 'fspro-screening-left-dialog',
  templateUrl: 'screening-left-dialog.component.html',
  styleUrls: ['./screening-left-dialog.component.scss']
})

export class ScreeningLeftDialog {

  isUnlimitedScreen = false;
  freshStartAllowed = 0;

  isProcess = false;

  constructor(public dialogRef: MatDialogRef<ScreeningLeftDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { film: IFilmDetailsDto, translations: any },
    private screeningService: ScreeningService
  ) {

    this.isUnlimitedScreen = data.film.availabilityWrapper.countOfFreshStartAllowed > 1000;
    this.freshStartAllowed = data.film.availabilityWrapper.countOfFreshStartAllowed - data.film.availabilityWrapper.countOfFreshStartConsumed;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  createNewScreen() {
    if (this.isProcess) return;
    this.isProcess = true;
    this.screeningService.archiveScreening(this.data.film.id).subscribe((data) => {
      window.location.href = this.data.film.filmAutoPlayUrl;
    });
  }

  onResume() {
    window.location.href = this.data.film.filmAutoPlayUrl;
  }
}
